
import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import AnimationConsulting from './icons/animationConsulting';
import AnimationDesign from './icons/animationDesign';
import AnimationDevelopment from './icons/animationDevelopment';
import AccordionItem from './AccordionItem';
import Logo from "./Logo";
import Services from './services/Services';
import { CONSULT_SERVICE, MENU, MENU_SERVICE, ServiceModel, Сompetences } from "./services/data";
import LogoSmall from './icons/logoSmall';

const VALID_EMAIL = /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/;

const Form = ({ handleCloseModal }) => {
    const [name, setName] = useState("");
    const [nameRequiredError, setNameRequiredError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailRequiredError, setEmailRequiredError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [messageRequiredError, setMessageRequiredError] = useState(false);
    const handleSendContacts = (event) => {
        event.preventDefault();
        let errorFoundFlag = false;
        setNameRequiredError(false);
        setEmailRequiredError(false);
        setEmailError(false);
        setMessageRequiredError(false);
        if (name.trim().length === 0) {
            setNameRequiredError(true);
            errorFoundFlag = true;
        }
        if (email.trim().length === 0) {
            setEmailRequiredError(true);
            errorFoundFlag = true;
        }
        if (email.trim().length > 0 && !VALID_EMAIL.test(email.trim())) {
            setEmailError(true);
            errorFoundFlag = true;
        }
        if (message.trim().length === 0) {
            setMessageRequiredError(true);
            errorFoundFlag = true;
        }
        if (errorFoundFlag) {
            return;
        }
        fetch('/contact', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name.trim(),
                email: email.trim(),
                phone,
                message: message.trim(),
            }),
        })
            .then(result => {
                if (result.status === 201) {
                    handleCloseModal(true);
                }
            })
            .catch(e => console.log(e))
            .finally(() => {
                handleCloseModal();
            });
    };
    const handleCloseForm = () => {
        handleCloseModal();
    };
    const handleSetName = (e) => {
        setName(e.target.value);
    };
    const handleSetEmail = (e) => {
        setEmail(e.target.value);
    };
    const handleSetPhone = (e) => {
        const phoneValue = e.target.value.trim();
        if (phoneValue === "" || /^\d+$/.test(phoneValue)) {
            if (phoneValue.startsWith("9") && phoneValue.length > 10) return;
            setPhone(phoneValue);
        }
    };
    const handleSetMessage = (e) => {
        setMessage(e.target.value);
    };
    return (
        <section className="shield">
            <form className="form form--modal" onSubmit={handleSendContacts}>
                <p className="button--close" onClick={handleCloseForm}>закрыть</p>
                <label className="form__label">Имя *</label>
                <div>
                    <input className={`form__input ${nameRequiredError ? "form__input--error" : ""}`} type="text" value={name} onChange={handleSetName}></input>
                </div>
                {nameRequiredError ? <label className="form__error">обязательное поле</label> : null}
                <label className="form__label">Email *</label>
                <div>
                    <input className={`form__input ${emailError || emailRequiredError ? "form__input--error" : ""}`} type="email" value={email} onChange={handleSetEmail}></input>
                </div>
                {emailRequiredError ? <label className="form__error">обязательное поле</label> : null}
                {emailError ? <label className="form__error">невалидный email</label> : null}
                <label className="form__label">Телефон</label>
                <div className="form__phone">
                    <input className="form__input form__input--in-text" type="phone" value={phone} onChange={handleSetPhone}></input>
                </div>
                <label className="form__label">Сообщение *</label>
                <div>
                    <textarea className={`form__input form__input--text-area ${messageRequiredError ? "form__input--error" : ""}`} value={message} onChange={handleSetMessage}></textarea>
                </div>
                {messageRequiredError ? <label className="form__error">обязательное поле</label> : null}
                {nameRequiredError || emailError || emailRequiredError || messageRequiredError ? <label className="form__error">Исправьте, пожалуйста, ошибки формы</label> : null}
                <button className="button button--form" type="submit">отправить</button>
            </form>
        </section>
    );
};

const Notification = ({ content, handleClose }) => {
    return (
        <div className="notification notification--modal">
            <p className="notification--modal-content">{content}</p>
            <button className="button button--form button--capitalized" onClick={handleClose}>Супер!</button>
        </div>
    );
};

const successSentMessage = "Ваше сообщение отправлено";

const MenuItem = ({ menuKey, handleSelectMenuMobile, serviceMenuIsSelected, handleOpenServicesMobile, activeService }) => {
    return (
        <>
            <h3 key={menuKey} className="menu-accordion__item" onClick={() => handleSelectMenuMobile(menuKey)}>{MENU[menuKey].title}</h3>
            {serviceMenuIsSelected && menuKey == MENU_SERVICE &&
                <div className="menu-accordion__sub-list">{
                    Object.keys(ServiceModel).map(serviceKey => {
                        return (
                            <h4 key={serviceKey}
                                className={`menu-accordion__sub-item ${serviceKey === activeService ? "menu-accordion__sub-item__active" : ""}`}
                                onClick={() => handleOpenServicesMobile(serviceKey)}>{ServiceModel[serviceKey].summary}</h4>
                        );
                    })
                }</div>
            }
        </>
    );
};

const App = () => {
    const [formIsOpened, setFormIsOpened] = useState(false);
    const [notificationIsOpened, setNotificationIsOpened] = useState(false);
    const [activeCompetence, setActiveCompetence] = useState('');
    const [servicesAreOpen, setServicesAreOpen] = useState(false);
    const [activeService, setActiveService] = useState('');
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const [serviceMenuIsSelected, setServiceMenuIsSelected] = useState(false);
    const notificationTransitions = useTransition(notificationIsOpened, {
        delay: 200,
        from: { opacity: 0, transform: "translateY(-40px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: { opacity: 0, transform: "translateY(-40px)" },
    });
    const handleOpenForm = () => {
        setFormIsOpened(true);
    };
    const handleCloseForm = (result) => {
        if (result) {
            setNotificationIsOpened(true);
        }
        setFormIsOpened(false);
    };
    const handleHideNotification = () => {
        setNotificationIsOpened(false);
    };
    const handleOpenMainPage = () => {
        setServicesAreOpen(false);
        setActiveService('');
    };
    const handleOpenServices = () => {
        setServicesAreOpen(!servicesAreOpen);
        if (!servicesAreOpen) {
            setActiveService(CONSULT_SERVICE);
        }
    };
    const handleOpenMenuMobile = () => {
        setMenuIsOpened(!menuIsOpened);
    };
    const handleSelectMenuMobile = (menuKey) => {
        if (menuKey == MENU_SERVICE) {
            setServiceMenuIsSelected(!serviceMenuIsSelected);
        }
    };
    const handleOpenServicesMobile = (type = '') => {
        setServicesAreOpen(true);
        setActiveService(type);
        setMenuIsOpened(false);
    };

    return (
        <>
            {formIsOpened ? <Form handleCloseModal={handleCloseForm} /> : null}
            {notificationTransitions((styles, itemState) => {
                return itemState && <animated.section style={styles} className="shield">
                    <Notification content={successSentMessage} handleClose={handleHideNotification} />
                </animated.section>;
            })}
            <div className="main-container">
                <header className="header">
                    <Logo onClick={() => handleOpenMainPage()} />
                    <LogoSmall />
                    <div className="header__menu">
                        <span className={`header__menu-item ${servicesAreOpen ? 'header__menu-item__active' : ''}`} onClick={() => handleOpenServices()}>{MENU.MENU_SERVICE.title}</span>
                        <button className="button header__button" onClick={handleOpenForm}>связаться с нами</button>
                    </div>
                    <div className="header__menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15"
                            className={`burger ${menuIsOpened ? "burger__active" : ""}`}
                            onClick={handleOpenMenuMobile}>
                            <rect className="burger__line burger__up" y="0" width="19" height="1" />
                            <rect className="burger__line burger__bottom" y="14" width="19" height="1" />
                        </svg>
                    </div>
                </header>
                {menuIsOpened && <menu className="menu">
                    <div className="menu__accordion">
                        {Object.keys(MENU).map((menuKey) => {
                            return <MenuItem key={menuKey}
                                menuKey={menuKey}
                                handleSelectMenuMobile={handleSelectMenuMobile}
                                serviceMenuIsSelected={serviceMenuIsSelected}
                                handleOpenServicesMobile={handleOpenServicesMobile}
                                activeService={activeService} />;
                        })}
                    </div>
                    <div className="menu__cta">
                        <button className="button" onClick={handleOpenForm}>связаться с нами</button>
                    </div>
                </menu>}
                {servicesAreOpen ?
                    <Services activeService={activeService} setActiveService={setActiveService} />
                    :
                    <>
                        <section className="preview-container">
                            <div className="branding-container">
                                <svg className="branding-container_1" xmlns="http://www.w3.org/2000/svg" fill="#665962" viewBox="0 0 192.19 192.19">
                                    <path id="d" className="e" d="M0,0V192.19H192.19L0,0Z" />
                                </svg>
                                <svg className="icon__elipse branding-container_2" xmlns="http://www.w3.org/2000/svg" fill="#445b6b" viewBox="0 0 407 190.98">
                                    <path id="d" className="e" d="M95.49,0C42.75,0,0,42.75,0,95.49c0,52.74,42.75,93.92,95.49,93.92l216.02,1.57c52.74,0,95.49-42.75,95.49-95.49S364.25,0,311.51,0H95.49Z" />
                                </svg>
                                <svg className="branding-container_3" xmlns="http://www.w3.org/2000/svg" fill="#fcbd3d" viewBox="0 0 191 191">
                                    <path id="d" className="e" d="M191,95.5C191,42.76,148.24,0,95.5,0S0,42.76,0,95.5s42.76,95.5,95.5,95.5,95.5-42.76,95.5-95.5" />
                                </svg>
                                <svg className="branding-container_4" xmlns="http://www.w3.org/2000/svg" fill="#ba4742" viewBox="0 0 191 191">
                                    <rect id="d" className="e" width="191" height="191" />
                                </svg>
                                <svg className="branding-container_5" xmlns="http://www.w3.org/2000/svg" fill="#76938e" viewBox="0 0 190.98 190.98">
                                    <path id="d" className="e" d="M190.98,95.49C190.98,42.75,148.23,0,95.49,0S0,42.75,0,95.49v95.49H190.98V95.49Z" />
                                </svg>
                            </div>
                            <h1 className="text-container">
                                <p>{Сompetences.CONSULT_COMPETENCE.title}</p>
                                <p>{Сompetences.ARCHI_COMPETENCE.title}</p>
                                <p>{Сompetences.DEVELOPMENT_COMPETENCE.title}</p>
                                <p>{Сompetences.MANAGE_COMPETENCE.title}</p>
                                <p>{Сompetences.OPTIMIZATION_COMPETENCE.title}</p>
                                <p>{Сompetences.TESTING_COMPETENCE.title}</p>
                            </h1>
                        </section>
                        <section className="preview-container__mobile">
                            <ul className="accordion">
                                {Object.keys(Сompetences).map((competenceKey, index) => (
                                    <AccordionItem
                                        key={competenceKey}
                                        content={Сompetences[competenceKey]}
                                        isActive={competenceKey == activeCompetence}
                                        handleActivate={() => competenceKey == activeCompetence ? setActiveCompetence('') : setActiveCompetence(competenceKey)}
                                    />
                                ))}
                            </ul>
                            <div className="preview__cta">
                                <button className="button" onClick={handleOpenForm}>связаться с нами</button>
                            </div>
                        </section>
                        <section className="consult-container">
                            <div className="consult-container__inner">
                                <h2 className="consult-title">{Сompetences.CONSULT_COMPETENCE.title}</h2>
                                <div className="consult-container__inner-text">
                                    <p className="consult-text">{Сompetences.CONSULT_COMPETENCE.text}</p>
                                    <AnimationConsulting />
                                </div>
                            </div>
                        </section>
                        <section className="design-development-container">
                            <div className="design-development-container__inner">
                                <div className="design-development-container__title">
                                    <h2 className="design-development-container__title-design">{Сompetences.ARCHI_COMPETENCE.title}</h2>
                                    <h2 className="design-development-container__title-development">{Сompetences.DEVELOPMENT_COMPETENCE.title}</h2>
                                </div>
                                <div className="design-development-container__inner-text">
                                    <p className="inner-text__design">{Сompetences.ARCHI_COMPETENCE.text}</p>
                                    <p className="inner-text__development">{Сompetences.DEVELOPMENT_COMPETENCE.text}</p>
                                </div>
                                <div className="design-development-container__animation">
                                    <AnimationDesign />
                                    <AnimationDevelopment />
                                </div>
                            </div>
                        </section>
                        <section className="cards-container">
                            <ul className="cards-container__inner">
                                <li className='card'>
                                    <h2 className="card__title">{Сompetences.MANAGE_COMPETENCE.title}</h2>
                                    <p className="card__text">{Сompetences.MANAGE_COMPETENCE.text}</p>
                                </li>
                                <li className='card card_1'>
                                    <h2 className="card__title">{Сompetences.OPTIMIZATION_COMPETENCE.title}</h2>
                                    <p className="card__text">{Сompetences.OPTIMIZATION_COMPETENCE.text}</p>
                                </li>
                                <li className='card card_2'>
                                    <h2 className="card__title">{Сompetences.TESTING_COMPETENCE.title}</h2>
                                    <p className="card__text">{Сompetences.TESTING_COMPETENCE.text}</p>
                                </li>
                            </ul>
                        </section>
                    </>
                }
                <footer className={`footer ${servicesAreOpen ? '' : 'footer__display-none'}`}>
                    <div className="footer__logo">
                        <Logo />
                        <p className="footer__address">ООО"Битапл" г. Москва, Автозаводская 23А, корпус 2</p>
                    </div>
                    <div className="footer__menu">
                        <div className="footer__menu-text">
                            <span onClick={() => handleOpenServices()}>услуги</span>
                            <div className="footer-menu__list">{
                                Object.keys(ServiceModel).map(serviceKey => {
                                    return (
                                        <h4 key={serviceKey}
                                            className="footer-menu__list-item"
                                            onClick={() => handleOpenServicesMobile(serviceKey)}>{ServiceModel[serviceKey].summary}</h4>
                                    );
                                })
                            }</div>
                        </div>
                        <a href="https://t.me/eshamrov">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="telegram-icon" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                            </svg>
                        </a>
                        <button className="button footer__button" onClick={handleOpenForm}>связаться с нами</button>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default App;
