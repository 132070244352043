import React from 'react';

const AnimationConsulting = () => {
    return (
        <svg className="animation__consulting" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 705 703">
            <rect className="v" width="703" height="703" />
            <circle className="ac" cx="73.54" cy="211.62" r="61" />
            <line className="aa" x1="143.54" y1="652.62" x2="143.54" y2="52.62" />
            <line className="aa" x1="283.54" y1="652.62" x2="283.54" y2="52.62" />
            <line className="aa" x1="423.54" y1="652.62" x2="423.54" y2="52.62" />
            <line className="aa" x1="563.54" y1="652.62" x2="563.54" y2="52.62" />
            <line className="aa" x1="653.54" y1="562.62" x2="53.54" y2="562.62" />
            <line className="aa" x1="653.54" y1="422.62" x2="53.54" y2="422.62" />
            <line className="aa" x1="653.54" y1="282.62" x2="53.54" y2="282.62" />
            <line className="aa" x1="653.54" y1="142.62" x2="53.54" y2="142.62" />
            <rect className="w" x="151.54" y="431.62" width="122" height="122" />
            <rect className="w" x="292.54" y="151.62" width="122" height="122" />
            <circle className="ac" cx="353.54" cy="352.62" r="61" />
            <path className="y" d="M553.54,74.62c0-33.69-27.31-61-61-61s-60,27.31-60,61l-1,138c0,33.69,27.31,61,61,61s61-27.31,61-61V74.62Z" />
            <path className="y" d="M491.54,553.62c33.69,0,61-27.31,61-61s-27.31-60-61-60l-138-1c-33.69,0-61,27.31-61,61s27.31,61,61,61h138Z" />
            <path className="x" d="M212.54,291.62c-33.69,0-61,27.31-61,61s27.31,61,61,61h61v-122h-61Z" />
            <path className="x" d="M554.54,352.62c0-33.69-27.31-61-61-61s-61,27.31-61,61v61h122v-61Z" />
            <path className="a" d="M628.62,401.77c2.02-.17,3.67-1.01,5.03-2.58,1.35-1.56,1.92-3.35,1.75-5.49s-1.02-3.68-2.61-5c-1.6-1.33-3.36-1.89-5.39-1.72s-3.72,1.01-5.06,2.57c-1.33,1.55-1.9,3.31-1.73,5.36s1.02,3.82,2.59,5.14c1.58,1.32,3.35,1.88,5.41,1.71Z" />
            <path className="a" d="M640.3,350.16c5.38-4.94,9.18-10.21,11.3-15.69,1.24-3.24,1.74-6.54,1.47-9.8-.2-2.45-.83-4.92-1.87-7.36-2.5-5.59-6.55-9.41-12.36-11.63-5.71-2.19-11.43-2.06-17.15,.45-5.66,2.51-9.52,6.57-11.78,12.39-.2,.5-.38,1-.54,1.51-1.09,3.4,1.9,6.72,5.4,6.06h0c1.08-.21,2.11-.73,2.79-1.6,.39-.5,.68-1.08,.83-1.71,.08-.35,.18-.68,.29-.97,1.27-3.3,3.57-5.69,6.82-7.14,3.32-1.46,6.54-1.51,9.84-.27,3.29,1.27,5.71,3.56,7.19,6.8,.59,1.43,.94,2.82,1.05,4.18,.15,1.83-.13,3.74-.83,5.66-1.29,3.27-3.47,6.71-6.51,10.28l-.64,.65c-6,5.4-10.41,11.72-13.11,18.78-.66,1.67-1.17,3.35-1.53,5.02-.66,3.06,1.81,5.9,4.93,5.64h.05c2.09-.18,3.75-1.74,4.2-3.79,.25-1.17,.61-2.35,1.06-3.53,1.85-4.88,4.8-9.47,8.76-13.63l.32-.31Z" />
            <path className="a" d="M213.31,262.55c2.02-.17,3.67-1.01,5.03-2.58,1.35-1.56,1.92-3.35,1.75-5.49s-1.02-3.68-2.61-5c-1.6-1.33-3.36-1.89-5.39-1.72s-3.72,1.01-5.06,2.57c-1.33,1.55-1.9,3.31-1.73,5.36s1.02,3.82,2.59,5.14c1.58,1.32,3.35,1.88,5.41,1.71Z" />
            <path className="a" d="M224.99,210.94c5.38-4.94,9.18-10.21,11.3-15.69,1.24-3.24,1.74-6.54,1.47-9.8-.2-2.45-.83-4.92-1.87-7.36-2.5-5.59-6.55-9.41-12.36-11.63-5.71-2.19-11.43-2.06-17.15,.45-5.66,2.51-9.52,6.57-11.78,12.39-.2,.5-.38,1-.54,1.51-1.09,3.4,1.9,6.72,5.4,6.06h0c1.08-.21,2.11-.73,2.79-1.6,.39-.5,.68-1.08,.83-1.71,.08-.35,.18-.68,.29-.97,1.27-3.3,3.57-5.69,6.82-7.14,3.32-1.46,6.54-1.51,9.84-.27,3.29,1.27,5.71,3.56,7.19,6.8,.59,1.43,.94,2.82,1.05,4.18,.15,1.83-.13,3.74-.83,5.66-1.29,3.27-3.47,6.71-6.51,10.28l-.64,.65c-6,5.4-10.41,11.72-13.11,18.78-.66,1.67-1.17,3.35-1.53,5.02-.66,3.06,1.81,5.9,4.93,5.64h.05c2.09-.18,3.75-1.74,4.2-3.79,.25-1.17,.61-2.35,1.06-3.53,1.85-4.88,4.8-9.47,8.76-13.63l.32-.31Z" />
            <path className="a" d="M72.31,540.55c2.02-.17,3.67-1.01,5.03-2.58,1.35-1.56,1.92-3.35,1.75-5.49s-1.02-3.68-2.61-5c-1.6-1.33-3.36-1.89-5.39-1.72s-3.72,1.01-5.06,2.57c-1.33,1.55-1.9,3.31-1.73,5.36s1.02,3.82,2.59,5.14c1.58,1.32,3.35,1.88,5.41,1.71Z" />
            <path className="a" d="M83.99,488.94c5.38-4.94,9.18-10.21,11.3-15.69,1.24-3.24,1.74-6.54,1.47-9.8-.2-2.45-.83-4.92-1.87-7.36-2.5-5.59-6.55-9.41-12.36-11.63-5.71-2.19-11.43-2.06-17.15,.45-5.66,2.51-9.52,6.57-11.78,12.39-.2,.5-.38,1-.54,1.51-1.09,3.4,1.9,6.72,5.4,6.06h0c1.08-.21,2.11-.73,2.79-1.6,.39-.5,.68-1.08,.83-1.71,.08-.35,.18-.68,.29-.97,1.27-3.3,3.57-5.69,6.82-7.14,3.32-1.46,6.54-1.51,9.84-.27,3.29,1.27,5.71,3.56,7.19,6.8,.59,1.43,.94,2.82,1.05,4.18,.15,1.83-.13,3.74-.83,5.66-1.29,3.27-3.47,6.71-6.51,10.28l-.64,.65c-6,5.4-10.41,11.72-13.11,18.78-.66,1.67-1.17,3.35-1.53,5.02-.66,3.06,1.81,5.9,4.93,5.64h.05c2.09-.18,3.75-1.74,4.2-3.79,.25-1.17,.61-2.35,1.06-3.53,1.85-4.88,4.8-9.47,8.76-13.63l.32-.31Z" />
            <polygon className="ab" points="151.54 133.62 273.54 133.62 273.54 11.62 151.54 133.62" />
            <path className="a" d="M572.05,544.22h.53v4.43c.1-.13,.21-.26,.33-.39,.63-.63,1.39-.94,2.27-.94s1.65,.31,2.28,.94,.94,1.39,.94,2.28-.31,1.64-.94,2.27-1.39,.94-2.28,.94-1.64-.31-2.27-.94c-.12-.12-.24-.25-.33-.39v1.17h-.53v-9.38Zm.53,5.77v1.1c.1,.5,.34,.94,.73,1.32,.52,.52,1.15,.78,1.88,.78s1.36-.26,1.88-.78,.78-1.14,.78-1.88-.26-1.36-.78-1.88-1.15-.78-1.88-.78-1.36,.26-1.88,.78c-.38,.38-.62,.83-.73,1.33Z" />
            <path className="a" d="M581.11,552.52c.5,.45,1.09,.68,1.77,.68,.73,0,1.36-.26,1.88-.78,.07-.07,.13-.13,.19-.21l.56,.2c-.1,.14-.22,.28-.35,.4-.63,.63-1.39,.94-2.28,.94s-1.64-.31-2.27-.94-.94-1.39-.94-2.28,.31-1.64,.94-2.27,1.39-.94,2.27-.94c.76,0,1.43,.23,2,.69,.09,.08,.19,.16,.28,.26,.04,.04,.07,.07,.1,.11l-.4,.4-3.75,3.75Zm3.36-4.11c-.46-.35-.99-.53-1.59-.53-.73,0-1.36,.26-1.88,.78s-.78,1.14-.78,1.88c0,.61,.18,1.14,.53,1.59l3.72-3.72Z" />
            <path className="a" d="M587.73,547.84v-2.45h.53v2.45h1.17v.53h-1.17v5.23h-.53v-5.23h-1.17v-.53h1.17Z" />
            <path className="a" d="M593.21,553.68c-.72,0-1.29-.21-1.72-.64s-.64-.96-.66-1.61v-3.59h.53v3.54c0,.49,.18,.9,.53,1.25,.35,.35,.79,.52,1.31,.52,.48,0,.9-.17,1.26-.52s.53-.76,.53-1.25v-3.54h.53v3.59c-.01,.65-.23,1.19-.66,1.61s-.98,.64-1.65,.64Z" />
            <path className="a" d="M597.28,547.84h.53v.81c.1-.13,.21-.26,.33-.39,.63-.62,1.39-.94,2.27-.94s1.65,.31,2.28,.94,.94,1.39,.94,2.28-.31,1.64-.94,2.27-1.39,.94-2.28,.94-1.64-.31-2.27-.94c-.12-.12-.24-.25-.33-.39v3.36h-.53v-7.95Zm.53,2.15v1.1c.1,.5,.34,.95,.73,1.33,.52,.52,1.15,.78,1.88,.78s1.36-.26,1.88-.78,.78-1.15,.78-1.88-.26-1.36-.78-1.88-1.15-.78-1.88-.78-1.36,.26-1.88,.78c-.38,.38-.62,.83-.73,1.33Z" />
            <path className="a" d="M605.62,544.22v9.38h-.53v-9.38h.53Z" />
            <path className="a" d="M608.47,552.52c.5,.45,1.09,.68,1.77,.68,.73,0,1.36-.26,1.88-.78,.07-.07,.13-.13,.19-.21l.56,.2c-.1,.14-.22,.28-.35,.4-.63,.63-1.39,.94-2.28,.94s-1.64-.31-2.27-.94-.94-1.39-.94-2.28,.31-1.64,.94-2.27,1.39-.94,2.27-.94c.76,0,1.43,.23,2,.69,.09,.08,.19,.16,.28,.26,.04,.04,.07,.07,.1,.11l-.4,.4-3.75,3.75Zm3.36-4.11c-.46-.35-.99-.53-1.59-.53-.73,0-1.36,.26-1.88,.78s-.78,1.14-.78,1.88c0,.61,.18,1.14,.53,1.59l3.72-3.72Z" />
            <path className="a" d="M614.45,552.78c.1,.1,.16,.22,.16,.37s-.05,.27-.16,.38-.23,.15-.37,.15-.27-.05-.37-.15-.16-.23-.16-.38,.05-.27,.16-.37,.23-.15,.37-.15,.27,.05,.37,.15Z" />
            <path className="a" d="M621.01,552.21l.56,.2c-.1,.14-.22,.28-.35,.4-.63,.63-1.39,.94-2.28,.94s-1.64-.31-2.27-.94-.94-1.39-.94-2.28,.31-1.64,.94-2.27,1.39-.94,2.27-.94,1.65,.31,2.28,.94c.13,.13,.24,.26,.35,.4l-.56,.21c-.06-.07-.12-.14-.19-.21-.52-.52-1.15-.77-1.88-.77s-1.36,.26-1.88,.78-.78,1.14-.78,1.88,.26,1.36,.78,1.88,1.14,.78,1.88,.78,1.36-.26,1.88-.78c.07-.07,.13-.13,.19-.21Z" />
            <path className="a" d="M625.84,547.32c.89,0,1.65,.31,2.28,.94s.94,1.39,.94,2.28-.31,1.64-.94,2.27-1.39,.94-2.28,.94-1.64-.31-2.27-.94-.94-1.39-.94-2.27,.31-1.65,.94-2.28,1.39-.94,2.27-.94Zm-1.88,1.34c-.52,.52-.78,1.15-.78,1.88s.26,1.36,.78,1.88,1.15,.78,1.88,.78,1.36-.26,1.88-.78,.78-1.14,.78-1.88-.26-1.36-.78-1.88-1.15-.78-1.88-.78-1.36,.26-1.88,.78Z" />
            <path className="a" d="M630.46,547.84h.53v.13c.33-.32,.72-.47,1.18-.47s.88,.17,1.21,.5c.09,.09,.17,.19,.23,.29,.07-.1,.15-.2,.24-.29,.33-.33,.73-.5,1.21-.5s.88,.17,1.21,.5c.32,.32,.48,.71,.5,1.15v4.44h-.53v-4.44h0c-.02-.3-.13-.56-.35-.78-.23-.23-.51-.35-.84-.35s-.6,.12-.83,.35-.35,.52-.35,.86v4.36h-.53v-4.44h0c-.02-.3-.13-.56-.35-.78-.23-.23-.51-.35-.84-.35s-.6,.12-.83,.35c-.2,.2-.32,.44-.35,.73v4.49h-.53v-5.75Z" />
            <polygon className="ab" points="413.54 571.62 291.54 571.62 291.54 693.62 413.54 571.62" />
        </svg>
    );
};

export default AnimationConsulting;

