import React from "react";
import { useTransition, useSpring, animated } from 'react-spring';

const AccordionItem = ({ content, isActive, handleActivate }) => {
    const transitions = useTransition(isActive, {
        from: { opacity: 0, transform: 'translate(0px, -80px)' },
        enter: { opacity: 1, transform: 'translate(0px, 0px)' },
        leave: { opacity: 0, transform: 'translate(0px, -80px)' },
    });

    const expand = useSpring({
        maxHeight: isActive ? `13.9rem` : '7rem',
    });

    return (
        <animated.li  className="accordion__item" style={expand}>
            <h2 className="accordion-item__title" onClick={handleActivate}>{content.title}</h2>
            {transitions((styles, itemState) => {
                return itemState &&
                <animated.div style={styles}>
                    <p className="accordion-item__content">{content.text}</p>
                </animated.div>;
            })}
        </animated.li>
    );
};


export default AccordionItem;