import React from 'react';

const AnimationDesign = () => {
    return (
        <svg className="animation__design" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 517.33 518.16">
            <rect id="e" className="ag" x="215.43" y="112.36" width="297" height="82" />
            <path id="g" className="al" d="M460.34,176.22h.34v2.88c.07-.09,.14-.17,.22-.25,.82-.82,2.14-.82,2.96,0,.82,.82,.82,2.14,0,2.96-.82,.82-2.14,.82-2.96,0h0c-.08-.08-.15-.16-.22-.25v.76h-.34v-6.09Zm.34,3.75v.72c.2,.93,1.11,1.52,2.04,1.33,.93-.2,1.52-1.11,1.33-2.04-.2-.93-1.11-1.52-2.04-1.33-.67,.14-1.18,.66-1.33,1.33" />
            <path id="h" className="al" d="M466.22,181.61c.31,.29,.72,.45,1.15,.44,.46,0,.9-.18,1.22-.51,.04-.04,.08-.09,.12-.13l.36,.13c-.07,.09-.14,.18-.22,.26-.82,.81-2.14,.81-2.95,0-.81-.82-.81-2.14,0-2.95,.75-.74,1.93-.81,2.76-.16,.06,.05,.12,.11,.18,.17,.02,.02,.04,.05,.06,.07l-.26,.26-2.43,2.44Zm2.18-2.67c-.69-.51-1.64-.44-2.25,.16-.61,.61-.68,1.57-.16,2.26l2.41-2.42Z" />
            <path id="i" className="al" d="M470.51,178.57v-1.59h.34v1.59h.76v.34h-.76v3.4h-.34v-3.4h-.76v-.34h.76Z" />
            <path id="j" className="al" d="M474.06,182.36c-.41,.02-.81-.13-1.11-.41-.28-.28-.43-.66-.42-1.05v-2.33h.34v2.3c0,.31,.12,.6,.35,.81,.22,.22,.53,.35,.85,.34,.31,0,.6-.12,.82-.34,.22-.21,.35-.51,.34-.81v-2.3h.34v2.33c.01,.79-.62,1.45-1.41,1.46-.03,0-.06,0-.09,0" />
            <path id="k" className="al" d="M476.7,178.57h.34v.52c.07-.09,.14-.17,.22-.25,.82-.82,2.14-.82,2.96,0,.82,.82,.82,2.14,0,2.96-.82,.82-2.14,.82-2.96,0h0c-.08-.08-.15-.16-.22-.25v2.18h-.34v-5.17Zm.34,1.4v.72c.2,.93,1.11,1.52,2.04,1.33,.93-.2,1.52-1.11,1.33-2.04-.2-.93-1.11-1.52-2.04-1.33-.67,.14-1.18,.66-1.33,1.33" />
            <rect id="l" className="al" x="481.77" y="176.22" width=".34" height="6.09" />
            <path id="m" className="al" d="M483.96,181.61c.31,.29,.72,.45,1.15,.44,.46,0,.9-.18,1.22-.51,.04-.04,.08-.09,.12-.13l.36,.13c-.07,.09-.14,.18-.22,.26-.82,.81-2.14,.81-2.95,0-.81-.82-.81-2.14,0-2.95,.75-.74,1.93-.81,2.76-.16,.06,.05,.12,.11,.18,.17,.02,.02,.04,.05,.06,.07l-.26,.26-2.43,2.44Zm2.18-2.67c-.69-.51-1.64-.44-2.25,.16-.61,.61-.67,1.57-.16,2.26l2.41-2.42Z" />
            <path id="n" className="al" d="M487.84,181.78c.13,.13,.13,.35,0,.48h0c-.13,.13-.35,.13-.48,0-.13-.13-.13-.35,0-.48h0c.13-.13,.35-.13,.48,0" />
            <path id="o" className="al" d="M492.09,181.41l.36,.13c-.07,.09-.14,.18-.22,.26-.82,.82-2.14,.82-2.96,0-.82-.82-.82-2.14,0-2.96,.82-.82,2.14-.82,2.96,0h0c.08,.08,.16,.17,.22,.26l-.36,.13s-.08-.09-.12-.14c-.67-.67-1.77-.67-2.44,0-.67,.67-.67,1.77,0,2.44,.67,.67,1.77,.67,2.44,0,.04-.04,.08-.09,.12-.13" />
            <path id="p" className="al" d="M495.22,178.23c1.15,0,2.09,.93,2.1,2.09,0,1.15-.93,2.09-2.09,2.1-1.15,0-2.09-.93-2.1-2.09,0-.56,.22-1.09,.61-1.48,.39-.4,.92-.62,1.47-.61m-1.22,.87c-.67,.67-.67,1.76,0,2.44,.67,.67,1.76,.67,2.44,0,.67-.67,.67-1.76,0-2.44-.32-.32-.76-.5-1.22-.5-.46,0-.9,.18-1.22,.51" />
            <path id="q" className="al" d="M498.22,178.57h.34v.09c.43-.42,1.12-.41,1.55,.01,.06,.06,.11,.12,.15,.19,.05-.07,.1-.13,.16-.19,.43-.43,1.13-.43,1.57,0,.2,.2,.32,.47,.32,.75v2.89h-.34v-2.89h0c-.02-.42-.38-.75-.8-.73-.19,0-.37,.09-.51,.22-.15,.15-.23,.35-.23,.56v2.83h-.34v-2.89h0c-.02-.41-.36-.73-.77-.73-.2,0-.4,.08-.54,.23-.13,.13-.21,.3-.22,.48v2.92h-.34v-3.74Z" />
            <path id="r" className="ak" d="M40.91,113.37c-21.89,.11-39.54,17.94-39.43,39.83,.11,21.73,17.7,39.32,39.43,39.43h39.56V113.37H40.91Z" />
            <path id="s" className="ak" d="M403.81,42.04c.11-21.85-17.51-39.64-39.36-39.76-21.85-.11-39.64,17.51-39.76,39.36,0,.13,0,.27,0,.4v39.63h79.11V42.04Z" />
            <path id="t" className="ah" d="M432.58,2.41V84.75h82.33L432.58,2.41Z" />
            <path id="u" className="ah" d="M296.64,2.41l-82.33,82.33h82.33V2.41Z" />
            <rect id="v" className="ag" x="108.43" y="112.36" width="82" height="82" />
            <path id="w" className="aj" d="M188.25,365.92c-.04-21.85-17.79-39.52-39.63-39.48-21.85,.04-39.52,17.79-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63h0" />
            <path id="x" className="aj" d="M188.25,476.87c-.04-21.85-17.78-39.52-39.63-39.48-21.85,.04-39.52,17.78-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63" />
            <path id="y" className="aj" d="M188.25,257.96c-.04-21.85-17.79-39.52-39.63-39.48-21.85,.04-39.52,17.79-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63h0" />
            <path id="a`" className="aj" d="M403.81,366.57c-.04-21.85-17.78-39.52-39.63-39.48-21.85,.04-39.52,17.78-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63" />
            <path id="aa" className="aj" d="M403.81,477.52c-.04-21.85-17.79-39.52-39.63-39.48-21.85,.04-39.52,17.79-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63h0" />
            <path id="ab" className="aj" d="M403.81,258.61c-.04-21.85-17.79-39.52-39.63-39.48-21.85,.04-39.52,17.79-39.48,39.63,.04,21.82,17.74,39.48,39.56,39.48,21.87-.02,39.58-17.76,39.56-39.63h0" />
            <path id="ac" className="ai" d="M40.56,2.41C18.69,2.43,.98,20.18,1,42.04h0c0,21.89,17.71,38.98,39.56,38.98l109.49,.65c21.89-.11,39.54-17.94,39.43-39.83-.11-21.73-17.7-39.32-39.43-39.43H40.56Z" />
            <path id="ad" className="ai" d="M80.46,257.86c.02-21.87-17.69-39.61-39.56-39.63h0c-21.85,0-38.91,17.75-38.91,39.63l-.65,219.27c-.11,21.85,17.51,39.64,39.36,39.75,21.85,.11,39.64-17.51,39.75-39.36,0-.13,0-.27,0-.4v-219.27Z" />
            <path id="ae" className="ai" d="M510.95,257.91c.02-21.87-17.69-39.61-39.56-39.63-21.85,0-38.91,17.74-38.91,39.63l-.65,218.27c-.11,21.85,17.51,39.64,39.36,39.75s39.64-17.51,39.75-39.36c0-.13,0-.26,0-.39v-218.27Z" />
            <path id="af" className="ai" d="M296.03,257.91c.02-21.87-17.69-39.61-39.56-39.63h0c-21.85,0-38.91,17.74-38.91,39.63l-.65,219.27c-.11,21.85,17.51,39.64,39.36,39.76,21.85,.11,39.64-17.51,39.76-39.36,0-.13,0-.27,0-.4v-219.27Z" />
        </svg>
    );
};
export default AnimationDesign;