export const CONSULT_SERVICE = "CONSULT_SERVICE";
export const DEVELOPMENT_SERVICE = "DEVELOPMENT_SERVICE";
export const DEVOPS_SERVICE = "DEVOPS_SERVICE";
export const TEST_SERVICE = "TEST_SERVICE";
export const ANALYSIS_SERVICE = "ANALYSIS_SERVICE";

export const ServiceModel = {
    [CONSULT_SERVICE]: {
        title: "Консультации, архитектурный надзор и\u00A0аудит",
        summary: "консультации",
        description: [
            "Разработка архитектурного решения на\u00A0основе требований к\u00A0существующей или\u00A0новой системе, разработка технического задания в\u00A0случае необходимости",
            "Исследование существующего или\u00A0предлагаемого архитектурного решения, аудит системы, выявление узких мест и\u00A0возможностей; экспертная оценка решения",
        ],
        reasons: [
            "вы нуждаетесь в\u00A0информации о\u00A0вашей системе, состоянии ее\u00A0компонентов в\u00A0данный момент",
            "вы нуждаетесь в\u00A0правильном развитии вашей системы и\u00A0задаетесь вопросом как\u00A0правильно добавить компоненты",
            "вам нужно знать правильно ли вы\u00A0разработали и\u00A0построили систему до\u00A0начала написания работ над\u00A0ней",
            "вам нужно выявить слабые места в\u00A0вашей системе",
        ],
        result: [
            "независимую оценку и\u00A0аудит вашей системы",
            "уверенность в\u00A0вашем архитектурном решении",
            "архитектурный надзор за\u00A0системой, ее\u00A0компонентами и\u00A0их\u00A0взаимодействием",
            "готовое архитектурное решение",
        ],
    },
    [DEVELOPMENT_SERVICE]: {
        title: "Аутсорс разработка веб-проектов и\u00A0сервисов (микросервисов) с\u00A0нуля или на\u00A0поддержку",
        summary: "Разработка",
        description: [
            "Наши архитекторы и\u00A0разработчики спроектируют, проанализируют и\u00A0дадут рекомендации на\u00A0основе ваших разработок",
            "Наши разработчики воплотят ваши идеи в\u00A0жизнь. Они\u00A0знают, как\u00A0соединить технологию и\u00A0бизнес.",
        ],
        reasons: [
            "у вас всегда был бизнес, и\u00A0может даже есть построенный на\u00A0шаблонах интернет-магазин или\u00A0корпоративный сайт, а\u00A0ситуация в\u00A0мире говорит о\u00A0том, что\u00A0нужно в\u00A0вопросах разработки довериться профессионалам, а\u00A0не\u00A0строить с\u00A0нуля целый отдел",
            "вам нужно отдать часть IT-задач уже слаженной команде с\u00A0подтвержденным опытом",
            "нужно ускорить процесс разработки и приблизить дату выхода продукта на\u00A0рынок",
        ],
        result: [
            "опытная команда специалистов, готовых применить свои знания к\u00A0вашему проекту",
            "уверенность в\u00A0качественной работе, которая будет завершена в\u00A0оптимальные сроки",
        ],
    },
    [DEVOPS_SERVICE]: {
        title: "DevOps услуги (консультации и\u00A0реализация пайплайнов, работа с\u00A0конфигурациями и\u00A0т.д.)",
        summary: "devops",
        description: [
            "покрываем все этапы жизненного цикла разработки программного обеспечения",
        ],
        reasons: [
            "вы хотите опередить конкурентов на\u00A0рынке, но\u00A0время выпуска каждой новой версии становится больше",
            "вы хотите быстрее получать обратную связь от\u00A0ваших клиентов, но\u00A0не\u00A0получаете ее\u00A0или получаете очень медленно",
        ],
        result: [
            "комплексная автоматизация ваших процессов",
            "ускорение выпуска новой версии вашего веб-приложения",
            "ускорение обратной связи на\u00A0всех этапах жизненного цикла веб-приложения",
            "настроенные процессы непрерывной поставки вашего веб-приложения",
            "реализация различных пайплайнов, настроенную работу с\u00A0конфигурациями и\u00A0т.д.",
        ],
    },
    [TEST_SERVICE]: {
        title: "Проведение нагрузочного тестирования",
        summary: "тестирование",
        description: [
            "Тестирование производительности (до\u00A050, 1000, 5000 пользователей одновременно)",
            "Стресс тестирование - кратковременное увеличение количества пользователей",
            "Тестирование стабильности - многочасовая нагрузка при\u00A0среднем количестве пользователей",
        ],
        reasons: [
            "Вы находитесь в\u00A0стадии запуска вашего проекта и\u00A0вам нужно понимание сможет ли ваше приложение выдержать большой трафик пользователей",
            "Вы внедрили крупное изменение, которое существенно влияет на\u00A0функционал (например, редизайн сайта, затрагивающий функционал)",
            "Вы планируете участвовать в\u00A0крупных маркетинговых акциях, которые привлекут большой трафик на\u00A0сайт (например, участие в\u00A0черной пятнице, или пост на\u00A0habr.com, vc.ru или другом медиа-ресурсе )",
            "Вам нужно быть уверенным в\u00A0вашем продукте и\u00A0поэтому вам просто необходимо периодически проверять стабильность вашего приложения",
        ],
        result: [
            "Отчет о\u00A0проведении НТ (Отражает результаты проведения НТ с\u00A0указанием на\u00A0«узкие места» в\u00A0текущей архитектуре платформы и\u00A0рекомендациями по\u00A0их\u00A0устранению силами Заказчика)",
        ],
    },
    [ANALYSIS_SERVICE]: {
        title: "Технический аудит (визуальная инспекция и\u00A0общий анализ качества организации текущей кодовой базы)",
        summary: "анализ",
        description: [
            "Верхнеуровневый анализ вашего проекта на\u00A0риски на\u00A0наличие угроз и\u00A0ошибок",
            "Визуальную инспекция (review) и\u00A0общий анализ качества организации текущей кодовой базы",
            "При\u00A0необходимости будет организовано проведение нагрузочного тестирования в\u00A0целях анализа качества текущей программной архитектуры и\u00A0наиболее важных архитектурных решений, влияющих на\u00A0производительность программного кода",
            "Проведение анализа архитектурных рисков, угроз и\u00A0ошибок в\u00A0программном коде и\u00A0архитектуре",
        ],
        reasons: [
            "вам необходимо мнение независомого эксперта о\u00A0кодовой базе вашего приложения",
            "вам нужен анализ архитектрных рисков, угроз и\u00A0ошибок в\u00A0вашей системе",
        ],
        result: [
            "Отчет о\u00A0проведении нагрузочного тестирования (отражает результаты проведения нагрузочного тестирования с\u00A0указанием на\u00A0«узкие места» в\u00A0текущей архитектуре и\u00A0рекомендациями по\u00A0их\u00A0устранению)",
            "Отчет по\u00A0итогам проекта (Отражает результаты анализ архитектурных рисков, угроз и\u00A0ошибок в\u00A0программном коде и\u00A0архитектуре платформы)",
        ],
    },
};

export const CONSULT_COMPETENCE = "CONSULT_COMPETENCE";
export const ARCHI_COMPETENCE = "ARCHI_COMPETENCE";
export const DEVELOPMENT_COMPETENCE = "DEVELOPMENT_COMPETENCE";
export const MANAGE_COMPETENCE = "MANAGE_COMPETENCE";
export const OPTIMIZATION_COMPETENCE = "OPTIMIZATION_COMPETENCE";
export const TESTING_COMPETENCE = "TESTING_COMPETENCE";

export const Сompetences = {
    [CONSULT_COMPETENCE]: {
        title: 'Консультация',
        text: 'Мы используем наш опыт работы с\u00A0данными и\u00A0проектами, чтобы определить, где мы можем предоставить ценность и\u00A0удовлетворить ваши уникальные\u00A0потребности',
    },
    [ARCHI_COMPETENCE]: {
        title: 'Архитектура и дизайн',
        text: 'Наши архитекторы и\u00A0разработчики спроектируют, проанализируют и\u00A0дадут рекомендации на\u00A0основе ваших\u00A0разработок',
    },
    [DEVELOPMENT_COMPETENCE]: {
        title: 'Разработка',
        text: 'Наши разработчики воплотят ваши идеи в\u00A0жизнь. Они знают, как\u00A0соединить технологию и\u00A0бизнес',
    },
    [MANAGE_COMPETENCE]: {
        title: 'Управление',
        text: 'Наши DevOps инженеры автоматизируют и\u00A0ускорят выпуск новых версий вашего продукта',
    },
    [OPTIMIZATION_COMPETENCE]: {
        title: 'Оптимизация',
        text: 'Наши разработчики проанализируют ваше приложение и\u00A0оптимизируют должным образом',
    },
    [TESTING_COMPETENCE]: {
        title: 'Тестирование',
        text: 'Наши разработчики проведут нагрузочные тесты вашей системы и\u00A0выявят недостатки в\u00A0ней, а\u00A0также дадут рекомендации по\u00A0их\u00A0устранению',
    },
};

export const MENU_SERVICE = "MENU_SERVICE";
export const MENU_CONTACTS = "MENU_CONTACTS";

export const MENU = {
    [MENU_SERVICE]: {
        title: 'услуги',
    },
    /*    [MENU_CONTACTS]: {
            title: "контакты",
        },*/
};

export const SERVICE_REASON_TITLE = "Почему вам это\u00A0нужно:";
export const SERVICE_RESULT_TITLE = "Что вы получаете в\u00A0результате:";
