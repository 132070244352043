import React from 'react';
import { SERVICE_REASON_TITLE, SERVICE_RESULT_TITLE, ServiceModel } from "./data";
import ServiceDescriptionItemIcon from "../icons/ServiceDescriptionItemIcon";
import ServiceReasonItemIcon from "../icons/ServiceReasonItemIcon";

const arrayOfResultCardIcons = [
    <svg key='icon0' className="services-result__card-icon" xmlns="http://www.w3.org/2000/svg" fill="#ba4742" viewBox="0 0 191 191">
        <rect className="e" width="191" height="191" />
    </svg>,

    <svg key='icon1' className="services-result__card-icon" xmlns="http://www.w3.org/2000/svg" fill="#76938e" viewBox="0 0 190.98 190.98">
        <path className="e" d="M190.98,95.49C190.98,42.75,148.23,0,95.49,0S0,42.75,0,95.49v95.49H190.98V95.49Z" />
    </svg>,

    <svg key='icon2' className="services-result__card-icon" xmlns="http://www.w3.org/2000/svg" fill="#76938e" viewBox="0 0 190.98 190.98">
        <path className="e" d="M190.98,95.49C190.98,42.75,148.23,0,95.49,0S0,42.75,0,95.49v95.49H190.98V95.49Z" />
    </svg>,

    <svg key='icon3' className="services-result__card-icon" xmlns="http://www.w3.org/2000/svg" fill="#ba4742" viewBox="0 0 191 191">
        <rect className="e" width="191" height="191" />
    </svg>,
    <svg key='icon4' className="services-result__card-icon" xmlns="http://www.w3.org/2000/svg" fill="#ba4742" viewBox="0 0 191 191">
        <rect className="e" width="191" height="191" />
    </svg>,
];

const ServiceItem = ({ activeServiceModel }) => (
    <>
        <p className='services__title__mobile'>{ServiceModel[activeServiceModel].summary}</p>
        <h1 className="services__title">{ServiceModel[activeServiceModel].title}</h1>
        <ul className="services-description">
            {ServiceModel[activeServiceModel].description.map(it => {
                return (
                    <li key={it} className="services-description__item">
                        <ServiceDescriptionItemIcon classes="services-description__item-icon" />
                        <p className="services-description__item-text">{it}</p>
                    </li>
                );
            })}
        </ul>
        <div className="services-reason">
            <h2 className="services__section-title services-reason__title">{SERVICE_REASON_TITLE}</h2>
            <ul className="services-reason__list">
                {ServiceModel[activeServiceModel].reasons.map(it => {
                    return (
                        <li key={it} className="services-reason__item">
                            <ServiceReasonItemIcon classes="services-reason__item-icon" />
                            <p className="services-reason__item-text">{it}</p>
                        </li>
                    );
                })}
            </ul>
        </div>
        <div className="services-result">
            <h2 className="services__section-title services-result__title">{SERVICE_RESULT_TITLE}</h2>
            <ul className={`services-result__cards ${ServiceModel[activeServiceModel].result.length === 1 ? 'services-result__cards__alone' : ''}`}>
                {ServiceModel[activeServiceModel].result.map((it, index) => {
                    return (
                        <li key={it} className="services-result__card">
                            {arrayOfResultCardIcons[index]}
                            <p className="services-result__card-text">
                                <span className="services-result__card-text-content">{it}</span>
                            </p>
                        </li>
                    );
                })}
            </ul>
        </div>
    </>
);

export default ServiceItem;