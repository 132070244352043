import React from 'react';

const LogoSmall = () => (
    <svg className="logo_small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 124">
        <g id="a"/>
        <g id="b">
            <g id="c">
                <path className="d" d="M0,0V124H124V0H0ZM20.91,103.47V59h11v44.47h-11Z"/>
            </g>
        </g>
    </svg>
);

export default LogoSmall;