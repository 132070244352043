import React from 'react';

const Logo = ({ onClick }) => (
    <svg onClick={onClick} className="logo home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.72 105">
        <g id="a" />
        <g id="b">
            <g id="c">
                <g>
                    <g>
                        <path className="e" d="M213.19,67.97l-.89-.32-.6,.72c-.46,.56-.97,1.11-1.51,1.65-4.18,4.18-9.09,6.21-15,6.21-4.94,0-9.31-1.51-13.03-4.49l30.78-30.77,3.38-3.38,.9-.9-.84-.95c-.28-.32-.58-.64-.9-.95-.82-.82-1.65-1.58-2.47-2.27-5.08-4.07-11.07-6.13-17.81-6.13-7.86,0-14.66,2.82-20.23,8.4-5.57,5.57-8.4,12.38-8.4,20.23s2.82,14.71,8.4,20.28c5.57,5.57,12.38,8.4,20.23,8.4s14.71-2.82,20.28-8.4c1.14-1.14,2.17-2.35,3.07-3.59l1.05-1.45-1.69-.6-4.72-1.69Zm-6.56-30.81l-29.3,29.3c-2.19-3.34-3.3-7.18-3.3-11.44,0-5.91,2.03-10.81,6.21-14.97,4.18-4.17,9.07-6.19,14.95-6.19,4.26,0,8.1,1.11,11.44,3.3Z" />
                        <path className="e" d="M297.91,30.82h-1.35v31.36c0,3.8-1.34,6.95-4.09,9.65-2.76,2.7-5.94,4.01-9.72,4.01-4.07,0-7.39-1.32-10.13-4.03-2.78-2.69-4.12-5.83-4.12-9.63V30.82h-7.17v31.76c.11,5.86,2.11,10.77,5.96,14.62,3.85,3.86,9.07,5.81,15.51,5.81,6.07,0,11.11-1.96,14.97-5.81,3.85-3.85,5.85-8.76,5.96-14.59V30.82h-5.82Z" />
                        <path className="e" d="M383.58,.11h-1.35V82.31h7.17V.11h-5.82Z" />
                        <path className="e" d="M107.83,22.76h1.35V.08h-7.17V22.76h5.82Z" />
                        <path className="e" d="M129.95,26.4c-7.54,0-14.92,3.06-20.24,8.4-3.06,3.07-7.63,10.15-7.7,15.58-.02,1.71-.02,2.3,0,3.21,0,.91,.02,2.15,0,5.17-.02,6.43,4.53,12.84,4.72,13.11,.88,1.19,1.88,2.35,2.98,3.45,5.57,5.57,12.38,8.39,20.23,8.39s14.71-2.83,20.26-8.4c5.55-5.57,8.37-12.38,8.37-20.23s-2.82-14.71-8.37-20.28c-5.55-5.57-12.37-8.4-20.26-8.4Zm21.21,28.68c0,5.91-2.03,10.81-6.21,14.97-4.18,4.17-9.09,6.19-15,6.19s-10.82-2.03-15-6.21c-3.01-3.01-4.95-6.51-5.77-10.42v-9.07c.82-3.94,2.76-7.46,5.77-10.46,4.18-4.18,9.09-6.21,15-6.21s10.82,2.03,15,6.21c4.18,4.18,6.21,9.09,6.21,15Z" />
                        <path className="e" d="M343.74,26.12c-7.54,0-14.92,3.06-20.24,8.4-3.06,3.07-7.63,10.15-7.7,15.58-.02,1.71-.02,2.3,0,3.21,0,.91,.02,2.15,0,5.17-.02,6.43,4.53,12.84,4.72,13.11,.88,1.19,1.88,2.35,2.97,3.45,5.57,5.57,12.38,8.39,20.23,8.39s14.71-2.83,20.26-8.4c5.55-5.57,8.37-12.38,8.37-20.23s-2.82-14.71-8.37-20.28c-5.55-5.57-12.37-8.4-20.26-8.4Zm-20.77,24.14c.82-3.94,2.76-7.46,5.77-10.46,4.18-4.18,9.09-6.21,15-6.21s10.82,2.03,15,6.21c4.18,4.18,6.21,9.09,6.21,15s-2.03,10.81-6.21,14.97c-4.18,4.17-9.09,6.19-15,6.19s-10.82-2.03-15-6.21c-3.01-3.01-4.95-6.51-5.77-10.42v-9.07Z" />
                        <path className="e" d="M450.03,69.72l-4.72-1.69-.89-.32-.6,.72c-.46,.56-.97,1.11-1.51,1.65-4.18,4.18-9.09,6.21-15,6.21-4.94,0-9.31-1.51-13.03-4.49l30.77-30.77,3.38-3.38,.9-.9-.84-.95c-.28-.32-.58-.64-.9-.95-.82-.82-1.65-1.58-2.47-2.27-5.08-4.07-11.07-6.13-17.81-6.13-7.86,0-14.66,2.82-20.23,8.4-5.57,5.57-8.4,12.38-8.4,20.23s2.82,14.71,8.4,20.28c5.57,5.57,12.38,8.4,20.23,8.4s14.71-2.82,20.28-8.4c1.14-1.14,2.17-2.35,3.07-3.59l1.05-1.45-1.69-.6Zm-11.28-32.5l-29.3,29.3c-2.19-3.34-3.3-7.18-3.3-11.44,0-5.91,2.03-10.81,6.21-14.97,4.18-4.17,9.07-6.19,14.95-6.19,4.26,0,8.1,1.11,11.44,3.3Z" />
                        <path className="d" d="M122.33,22.63h1.35V0h-7.11V22.63h5.76Z" />
                        <polygon className="e" points="317.36 87.7 316.01 87.7 316.01 89.04 316.01 103.65 316.01 105 317.36 105 321.83 105 323.18 105 323.18 103.65 323.18 89.04 323.18 87.7 321.83 87.7 317.36 87.7" />
                        <path className="d" d="M331.91,87.64h-1.35v17.27h7.11v-17.27h-5.76Z" />
                        <path className="e" d="M242.16,30.82V10.05h-7.17V30.82h-5.18v7.17h5.18v44.33h7.17V37.99h9.94v-7.17h-9.94Z" />
                    </g>
                    <path className="e" d="M0,.51V84.1H83.59V.51H0ZM14.1,70.26v-29.98h7.42v29.98h-7.42Z" />
                </g>
            </g>
        </g>

    </svg>
);

export default Logo;